/** @jsx jsx */

import {Flex,jsx,Box,Text} from 'theme-ui'
import Layout from '../../components/layout'
import {useSpring,animated,config} from 'react-spring'
import MySEO from '../../components/seo'

const Sogehts =() => {

    const props = useSpring({from:{opacity:0,marginLeft:-300},to:{opacity:1,marginLeft:0},config:config.slow}) 
    return(
        <Layout>
          <MySEO title="So werden Sie Mitglied" decription="Anleitung für den Antrag einer Mitgliedschaft beim K.O.M" />
            <Flex sx={{width:"100%",p:3,flexDirection:["column","column","row"]}}>
           
          <Box sx={{flex:1,maxWidth:"590px",mr:2}}>
          <animated.div style={props}>
           <Box >
           
           <Text sx={{fontSize:1,mb:3}}>
            Wir vom K.O.M. freuen uns, wenn Sie dabei sein möchten und das Kulturnetzwerk mit Informationen zu Ihrem kulturellen Schaffen erweitern!
            </Text>
          
           </Box>
           
            <Text sx={{fontSize:[4,5],my:4,lineHeight:1.2}}>
            Bitte schicken Sie uns Ihre Daten via Email an:
            </Text>
            
            <Text sx={{fontSize:1}}>
            <strong>kontakt@kulturnetz-oberes-mittelrheintal.org</strong><br/> oder,
wenn Ihre Dateien zu groß sind für eine normale Email,über <br/> <strong>www.wetransfer.com</strong>
            </Text>
            <Text sx={{my:3}}>

Wir benötigen von Ihnen folgende Daten:<br/><br/>
<strong>Bilder:</strong><br/>
1-4 Bilder Ihres Werks und, wenn Sie möchten, 1 Portrait von Ihrer Person.
            </Text>
           
            <Box>
           
            <Text>
                <strong>Text</strong>
                <ol sx={{li:{p:0,m:0}}}>
                    <li>
                   einen möglichst kurzen Titel,


                    </li>
                    <li>
                    Ihren Namen,
                    </li>
                    <li>den Ort, wo Sie arbeiten bzw. Ihre Werke zu besichtigen
sind,</li>
<li>die Branche, nach der man Sie in unserer Suche finden
kann,</li>
<li>wenn eine Website existiert den URL, wo man Ihre
Werke betrachten kann,</li>
<li>einen Langtext zur Beschreibung Ihrer Person und/oder
Ihres Schaffens mit maximal 2.000 Zeichen.</li>
<li>Bildunterschriften zu dem von Ihnen zur Verfügung gestellten Bildmaterial</li>
                </ol>
            </Text>

            </Box>
            </animated.div>
            </Box>
            <Box>
                <animated.div style={props}>
            <Box sx={{flex:1,minWidth:320,mT:5,ml:[0,3],display:'flex',flexDirection:'column',justifyCenter:'flex-start'}}>
            <Box sx={{background:"gray",m:2,width:["100%","70%","100%"],height:320,display:'flex', justifyContent:'center',alignItems:"center"}}>Portrait</Box>
               
               <Box sx={{background:"gray",m:2,width:["100%","70%","100%"],height:320,display:'flex', justifyContent:'center',alignItems:"center"}}>Bild 1</Box>
               <Box sx={{background:"gray",m:2,width:["100%","70%","100%"],height:320,display:'flex', justifyContent:'center',alignItems:"center"}}>Bild 2</Box>
               <Box sx={{background:"gray",m:2,width:["100%","70%","100%"],height:320,display:'flex', justifyContent:'center',alignItems:"center"}}>Bild 3</Box>
            </Box>
            </animated.div>
       </Box>
    
                       </Flex>
                   
        </Layout>
    )
}
export default Sogehts